import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import EmptyBox from "~/common/components/icons/EmptyBox";

interface EmptyStateProps {
  message: string;
  icon?: ReactNode;
  className?: string;
  messageClassName?: string;
}

export const EmptyState = ({
  message,
  className,
  icon,
  messageClassName,
}: EmptyStateProps) => {
  return (
    <div
      className={twMerge(
        "flex h-full w-full flex-col items-center justify-center gap-4",
        className,
      )}
    >
      {icon ?? <EmptyBox />}
      <p
        className={twMerge(
          "text-center text-base leading-5 text-gray-60",
          messageClassName,
        )}
      >
        {message}
      </p>
    </div>
  );
};

import { z } from "zod";

import { TEXT_MEDIUM_LENGTH } from "~/patients/utils";

export const cancelRequestSchema = z.object({
  justification: z
    .string()
    .max(
      TEXT_MEDIUM_LENGTH,
      `Please ensure justification does not exceeds ${TEXT_MEDIUM_LENGTH} characters.`,
    )
    .optional(),
});

export type CancelRequestFormValues = z.infer<typeof cancelRequestSchema>;

import { Navigate, useNavigate } from "react-router-dom";

import {
  EmptyState,
  LoadingState,
  MailBoxIcon,
  OngoingWellness,
  OpenEnvelope,
  Typography,
} from "~/common/components";
import { PATIENT_STATUS } from "~/common/constants";
import {
  ConsultationsBundleCodes,
  consultationTitle,
  TYPOGRAPHY_VARIANTS,
} from "~/common/utils";
import { ROUTES } from "~/patients/router";
import {
  AppointmentConsultationCard,
  ConsultationCard,
} from "~/patients/screens/consultations";
import {
  useGetCurrentIntakeBundle,
  useGetCurrentScreeningBundle,
} from "../hooks";
import { useGetCurrentPatient } from "../hooks/queries/useGetCurrentPatient";
import { getConsultationText } from "../utils/getConsultationText";

export const LeadPatientHomeScreen = () => {
  const navigate = useNavigate();
  const { data: user, isFetching } = useGetCurrentPatient();
  const { preferred_reference: providerName } = { ...user?.provider };
  const isInactivePatient = !!user?.archived_at;

  const { data: screeningData, isFetching: isFetchingScreening } =
    useGetCurrentScreeningBundle();

  const { data: intakeData, isFetching: isFetchingIntake } =
    useGetCurrentIntakeBundle();

  if (user?.status === PATIENT_STATUS.ongoing_care)
    return <Navigate to={ROUTES.notFound} />;

  if (user && !user.email_verified_at)
    return <Navigate to={ROUTES.verifyEmail} />;

  if (user && !user.settings?.screening_first_screen_visited)
    return <Navigate to={ROUTES.leadScreeningIntroScreen} />;

  if (isFetching || isFetchingScreening || isFetchingIntake)
    return <LoadingState />;

  if (isInactivePatient)
    return (
      <EmptyState
        icon={<MailBoxIcon />}
        message="Your consultation has ended. Please contact our team if you have any questions."
      />
    );

  return (
    <div className="flex size-full justify-center">
      <div className="flex size-full flex-col gap-4 px-6 py-8 md:w-2/3 lg:w-1/2">
        {screeningData ? (
          <ConsultationCard
            completed={
              screeningData.sections_completed === screeningData.total_sections
            }
            title={
              consultationTitle[screeningData.code as ConsultationsBundleCodes]
            }
            totalSections={screeningData.total_sections}
            currentSection={screeningData.sections_completed}
            handleCompleteConsultation={() =>
              navigate(ROUTES.completeScreeningConsultation)
            }
            duration={screeningData.duration}
          />
        ) : (
          <EmptyState message="You don't have pending activity yet, we're looking forward to get in touch with you!" />
        )}
        {intakeData && (
          <>
            <ConsultationCard
              completed={
                intakeData.sections_completed === intakeData.total_sections
              }
              subtitle="Online assessment"
              title={
                consultationTitle[intakeData.code as ConsultationsBundleCodes]
              }
              totalSections={intakeData.total_sections}
              currentSection={intakeData.sections_completed}
              handleCompleteConsultation={() =>
                navigate(ROUTES.leadIntakeIntroScreen)
              }
              duration={intakeData.duration}
            />
            <AppointmentConsultationCard title="Consultation Part 2" />
          </>
        )}

        <div className="flex size-full flex-col items-center justify-center gap-3">
          {(user?.status === PATIENT_STATUS.screening_started ||
            user?.status === PATIENT_STATUS.consultation_requested) && (
            <OngoingWellness />
          )}
          {user?.status === PATIENT_STATUS.screening_submitted && (
            <OpenEnvelope />
          )}
          {user?.status === PATIENT_STATUS.referred_after_screening && (
            <MailBoxIcon />
          )}
          <Typography
            className="w-3/4 text-center text-base"
            variant={TYPOGRAPHY_VARIANTS.secondary}
          >
            {user?.status &&
              providerName &&
              getConsultationText(user?.status, providerName)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

import { twMerge } from "tailwind-merge";

import { Styled } from "~/common/utils";
import BackArrow from "../icons/BackArrow";
import CrossIcon from "../icons/CrossIcon";

interface CloseButtonProps extends Styled {
  onClose: () => void;
}

export const CloseButton = ({ onClose, className }: CloseButtonProps) => (
  <button
    onClick={onClose}
    className={twMerge(
      "flex w-full items-center justify-start gap-1 md:justify-end",
      className,
    )}
  >
    <CrossIcon className="hidden md:flex" />
    <BackArrow className="flex w-5 md:hidden" />
    <p className="flex w-fit font-medium text-gray-70 md:hidden">Back</p>
  </button>
);

import { z } from "zod";

interface StringSchemaProps {
  inputName: string;
  required: boolean;
  maxLength?: number;
}

export const createGenericStringSchema = ({
  inputName,
  required,
  maxLength,
}: StringSchemaProps) => {
  const baseSchema = required
    ? z.string().min(1, { message: `Please enter ${inputName}.` })
    : z.string();

  return maxLength
    ? baseSchema.max(maxLength, {
        message: `${inputName} should be less than ${maxLength} characters`,
      })
    : baseSchema;
};

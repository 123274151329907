import { AUDIO_MAX_DURATION, AUDIO_MIN_DURATION } from "~/common/constants";
import {
  SCREENING_QUESTION_CODES,
  SCREENING_QUESTION_TYPES,
} from "../screeningQuestionsData";

export const audioQuestions = {
  [SCREENING_QUESTION_CODES.how_can_we_help]: {
    title: "What's going on and how can I be of help?",
    type: SCREENING_QUESTION_TYPES.audio,
    minDuration: AUDIO_MIN_DURATION,
    maxDuration: AUDIO_MAX_DURATION,
  },
} as const;

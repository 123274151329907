import type { ComponentPropsWithoutRef, ForwardedRef } from "react";
import { twMerge as tw } from "tailwind-merge";

import Spinner from "~/common/components/ui/Spinner";
import { BUTTON_VARIANTS } from "~/common/constants/variants";
import { forwardRef } from "~/common/utils/forwardRef";
import { IconWrapper } from "~/patients/ui/IconWrapper";

export const buttonVariants = [
  "primary",
  "outline",
  "outline-white",
  "outline-black",
  "outline-none",
  "tertiary-link",
  "outline-none-underline",
] as const;
export type ButtonVariant = (typeof buttonVariants)[number];

export const buttonSizes = ["sm", "md", "lg"] as const;
export type ButtonSize = (typeof buttonSizes)[number];

export interface ButtonProps extends ComponentPropsWithoutRef<"button"> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  left?: React.ReactNode;
  right?: React.ReactNode;
  isLoading?: boolean;
}

export const Button = forwardRef(
  (
    {
      type = "button",
      className,
      variant = "primary",
      size = "md",
      left,
      right,
      disabled = false,
      isLoading = false,
      children,
      ...props
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => (
    <button
      ref={ref}
      type={type}
      className={tw(
        "flex w-full items-center gap-2 rounded-md border border-transparent font-medium focus:outline-none focus:ring-2 focus:ring-offset-0",

        !disabled && [
          variant === BUTTON_VARIANTS.primary &&
            "justify-center bg-primary-500 text-white hover:bg-blue-400 focus:bg-blue-400 focus:ring-0 active:bg-primary-500",
          variant === BUTTON_VARIANTS.outline &&
            "justify-center border border-gray-300 text-gray-70 hover:bg-slate-100 focus:bg-slate-50 focus:ring-0",
          variant === BUTTON_VARIANTS.outline_black &&
            "justify-center border-black bg-neutral-25 text-black hover:bg-slate-100 focus:bg-slate-50 focus:ring-0",
          variant === BUTTON_VARIANTS.outline_white &&
            "border-white text-white hover:bg-slate-100 focus:bg-slate-50 focus:ring-0",
          variant === BUTTON_VARIANTS.outline_none &&
            "bg-transparent text-gray-600 hover:text-gray-700 focus:text-gray-700 focus:ring-0",
          variant === BUTTON_VARIANTS.tertiary_link &&
            "text-indigo hover:text-indigo-700 focus:text-indigo-700 focus:ring-1 focus:ring-indigo-700",
          variant === BUTTON_VARIANTS.outline_none_underline &&
            "justify-center bg-transparent text-gray-600 underline hover:text-gray-700 focus:text-gray-700 focus:ring-0",
        ],

        disabled && [
          variant === BUTTON_VARIANTS.primary &&
            "justify-center bg-gray-300 text-gray-400",
          variant === BUTTON_VARIANTS.outline &&
            "border-gray-400 text-gray-400",
          variant === BUTTON_VARIANTS.outline_none &&
            "bg-gray-300 text-gray-400",
          variant === BUTTON_VARIANTS.tertiary_link && "text-gray-400",
          variant === BUTTON_VARIANTS.outline_black &&
            "border-gray-20 bg-neutral-5 text-gray-60",
          variant === BUTTON_VARIANTS.outline_none_underline &&
            "bg-gray-300 text-gray-400",
        ],

        size === "sm" && "px-4 py-2 text-sm",
        size === "md" && "px-5 py-3 text-base leading-5",
        size === "lg" && "px-6 py-4 text-lg leading-[22px]",

        !children && [
          size === "sm" && "p-2",
          size === "md" && "p-3",
          size === "lg" && "p-4",
        ],

        className,
      )}
      disabled={disabled}
      {...props}
    >
      {isLoading ? (
        <Spinner className="h-5 w-5" />
      ) : (
        <>
          {left && <IconWrapper size={size}>{left}</IconWrapper>}
          {children}
          {right && <IconWrapper size={size}>{right}</IconWrapper>}
        </>
      )}
    </button>
  ),
);

import { z } from "zod";

import { IA_TEXT_MAX_LENGTH } from "~/common/constants";
import { createGenericStringSchema } from "~/common/utils/createGenericStringSchema";

const createBasicPatientInformationSchema = (requiredFields: boolean) =>
  z.object({
    date_of_birth: createGenericStringSchema({
      inputName: "date of birth",
      required: requiredFields,
    }),
    occupation: createGenericStringSchema({
      inputName: "occupation",
      required: requiredFields,
      maxLength: 200,
    }),
    marital_status: createGenericStringSchema({
      inputName: "marital status",
      required: requiredFields,
      maxLength: 50,
    }),
    gender: createGenericStringSchema({
      inputName: "gender",
      required: requiredFields,
    }),
  });

const createMentalStatusExaminationReportSchema = (requiredFields: boolean) =>
  z.object({
    appearance: createGenericStringSchema({
      inputName: "appearance",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
    behavior: createGenericStringSchema({
      inputName: "behavior",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
    speech: createGenericStringSchema({
      inputName: "speech",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
    mood: createGenericStringSchema({
      inputName: "mood",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
    affect: createGenericStringSchema({
      inputName: "affect",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
    thought_process: createGenericStringSchema({
      inputName: "thought process",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
    thought_content: createGenericStringSchema({
      inputName: "thought content",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
    attention_and_concentration: createGenericStringSchema({
      inputName: "attention and concentration",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
    judgment_and_insight: createGenericStringSchema({
      inputName: "judgment and insight",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
  });

const createConsultationReportSchema = (requiredFields: boolean) =>
  z.object({
    basic_patient_information:
      createBasicPatientInformationSchema(requiredFields),
    chief_complaint: createGenericStringSchema({
      inputName: "chief complaint",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
    history_of_present_illness: createGenericStringSchema({
      inputName: "history of presented illness",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
    past_psychiatric_history: createGenericStringSchema({
      inputName: "past psychiatric history",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
    past_medical_history: createGenericStringSchema({
      inputName: "past medical history",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
    medications: createGenericStringSchema({
      inputName: "medications",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
    substances: createGenericStringSchema({
      inputName: "substances",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
    allergies: createGenericStringSchema({
      inputName: "allergies",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
    past_personal_history: createGenericStringSchema({
      inputName: "past personal history",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
    education_history: createGenericStringSchema({
      inputName: "education history",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
    family_history: createGenericStringSchema({
      inputName: "family history",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
    psychiatric_review: createGenericStringSchema({
      inputName: "psychiatric review",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
    mental_status_examination:
      createMentalStatusExaminationReportSchema(requiredFields),
    assessment: createGenericStringSchema({
      inputName: "assessment",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
    plan_of_care: createGenericStringSchema({
      inputName: "plan of care",
      required: requiredFields,
      maxLength: IA_TEXT_MAX_LENGTH,
    }),
  });

export const consultationReportSchema = z.discriminatedUnion("saveData", [
  z.object({
    saveData: z.literal(false),
    data: createConsultationReportSchema(true),
  }),
  z.object({
    saveData: z.literal(true),
    data: createConsultationReportSchema(false),
  }),
]);

export type ConsultationReportSchemaType = z.infer<
  typeof consultationReportSchema
>;

const consultationReportDataSchema = createConsultationReportSchema(true);
const basicPatientInformationSchema = createBasicPatientInformationSchema(true);
const mentalStatusExaminationReportSchema =
  createMentalStatusExaminationReportSchema(true);

export type ConsultationReportDataSchemaType = z.infer<
  typeof consultationReportDataSchema
>;
export type BasicPatientInformationSchemaType = z.infer<
  typeof basicPatientInformationSchema
>;

export type MentalStatusExaminationReportSchemaType = z.infer<
  typeof mentalStatusExaminationReportSchema
>;

import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { TYPOGRAPHY_VARIANTS } from "~/common/utils";
import { Typography } from "../ui";
import { AnswerLayout } from "./AnswerLayout";

export interface QuestionAnswerLayoutProps extends PropsWithChildren {
  question?: string;
  description?: string;
  answer?: string;
  furtherDescription?: string;
  isPreview?: boolean;
  answerClassName?: string;
  containerClassName?: string;
}

export const QuestionAnswerLayout = ({
  question,
  description,
  answer,
  furtherDescription,
  children,
  isPreview,
  answerClassName,
  containerClassName,
}: QuestionAnswerLayoutProps) => (
  <div
    className={twMerge(
      "flex w-full flex-col gap-4 rounded-lg border border-neutral-700 p-3 text-gray-70",
      isPreview && "border-none bg-primary-20",
      containerClassName,
    )}
  >
    <div className="flex w-full flex-col gap-2">
      <Typography className="font-medium first-letter:uppercase">
        {question}
      </Typography>
      {description && !isPreview && (
        <Typography variant={TYPOGRAPHY_VARIANTS.light}>
          {description}
        </Typography>
      )}
    </div>
    {answer && (
      <AnswerLayout isPreview={isPreview}>
        <Typography
          className={twMerge(
            "font-medium text-primary-600 first-letter:uppercase",
            answerClassName,
          )}
        >
          {answer}
        </Typography>
        {furtherDescription && (
          <Typography
            variant={TYPOGRAPHY_VARIANTS.italic}
            className="font-normal first-letter:uppercase"
          >
            {furtherDescription}
          </Typography>
        )}
      </AnswerLayout>
    )}
    {children}
  </div>
);

import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";

import { TransitionEffect } from "~/common/components";
import {
  CompletedListJobsAnswer,
  ListJobsQuestionData,
} from "~/common/utils/consultations/shared";
import {
  AddElementButton,
  ConsultationQuestionsLayout,
} from "~/patients/components/consultations/shared";
import { useJobItem } from "~/patients/hooks/consultations/useJobItem";
import {
  JobsFormValues,
  ListJobsFormValues,
  ListJobsSchema,
} from "~/patients/utils/consultations/intakes";
import { JobInput } from "./inputs/JobInput";

interface ListJobFormProps {
  question: ListJobsQuestionData;
  handleGoBack: () => void;
  onSubmit: (answer: ListJobsFormValues) => void;
  isSubmitting: boolean;
  disableBack: boolean;
  completedAnswers: CompletedListJobsAnswer;
}

export const ListJobForm = ({
  completedAnswers,
  disableBack,
  handleGoBack,
  isSubmitting,
  onSubmit,
  question,
}: ListJobFormProps) => {
  const { title, subtitle } = question;

  const {
    handleSubmit,
    watch,
    register,
    control,
    formState: { errors },
  } = useForm<ListJobsFormValues>({
    defaultValues: { answer: { ...completedAnswers } },
    resolver: zodResolver(ListJobsSchema),
  });

  const { addEmptyJob, isEmptyJobInput } = useJobItem();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "answer.jobs_you_have_held",
  });

  const saveAnswers: SubmitHandler<ListJobsFormValues> = (data) =>
    onSubmit(data);

  const jobs = watch("answer.jobs_you_have_held") || [];
  const disableNext = jobs.length === 0;

  return (
    <ConsultationQuestionsLayout
      handleNext={handleSubmit(saveAnswers)}
      handleBack={handleGoBack}
      disableNext={disableNext}
      isSubmitting={isSubmitting}
      title={title}
      subtitle={subtitle}
      disableBack={disableBack}
    >
      {fields.map((item, index) => (
        <TransitionEffect key={item.id}>
          <JobInput
            handleDelete={() => remove(index)}
            isMedicationDataEmpty={isEmptyJobInput(index, fields, watch)}
            register={(data: keyof JobsFormValues) =>
              register(`answer.jobs_you_have_held.${index}.${data}`)
            }
            errors={errors.answer?.jobs_you_have_held?.[index]}
            watch={(data: keyof JobsFormValues) =>
              watch(`answer.jobs_you_have_held.${index}.${data}`)
            }
          />
        </TransitionEffect>
      ))}
      <AddElementButton onClick={() => addEmptyJob(append)} title="Add job" />
    </ConsultationQuestionsLayout>
  );
};

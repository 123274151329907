import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, SubmitHandler, useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { CancelSubmitButtons } from "~/common/components";
import Modal from "~/common/components/Modal";
import Input from "~/common/components/ui/Input";
import Select from "~/common/components/ui/select/Select";
import Spinner from "~/common/components/ui/Spinner";
import TextArea from "~/common/components/ui/Textarea";
import { OTHER_VALUE } from "~/common/constants";
import { MedicationStatusModal } from "~/common/types";
import { NewPharmacyFormInputs } from "~/patients/components";
import { useGetPatientPharmacies } from "~/patients/hooks/queries/pharmacy";
import { useOnSubmitScript } from "~/patients/hooks/queries/scripts/useOnSubmitScript";
import {
  generatePharmaciesOptions,
  NewPharmacyFormValuesType,
  RequestScriptFormValuesType,
  requestScriptSchema,
} from "~/patients/utils";

interface RequestScriptModalProps {
  onClose: () => void;
  medicationName?: string;
  medicationId: string;
  setMedicationStatusModal: (value: MedicationStatusModal) => void;
}

export const RequestScriptModal = ({
  onClose,
  medicationName,
  medicationId,
  setMedicationStatusModal,
}: RequestScriptModalProps) => {
  const { data: pharmaciesList, isLoading } = useGetPatientPharmacies();
  const defaultPharmacy = pharmaciesList?.find(
    (pharmacy) => pharmacy.is_default,
  );
  const {
    register,
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<RequestScriptFormValuesType>({
    resolver: zodResolver(requestScriptSchema),
    defaultValues: {
      medication: medicationName,
      isNewPharmacy: false,
      pharmacy: defaultPharmacy?.id,
    },
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const createPharmacy = watch("isNewPharmacy");

  const { requestScript, isPendingCreateScript } = useOnSubmitScript(
    handleClose,
    setMedicationStatusModal,
  );

  const onSubmit: SubmitHandler<RequestScriptFormValuesType> = (formData) => {
    requestScript(formData, medicationId);
  };

  const isNewPharmacyForm = (
    data: boolean,
    errors: FieldErrors<RequestScriptFormValuesType>,
  ): errors is FieldErrors<NewPharmacyFormValuesType> => {
    return data;
  };

  return (
    <Modal
      show={!!medicationName}
      onClose={handleClose}
      title="Request prescription"
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <form
          className="flex h-full flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div
            className={twMerge(
              "flex h-full w-full flex-col gap-4",
              createPharmacy &&
                "max-h-screen-70 overflow-auto sm:max-h-custom-600",
            )}
          >
            <Input
              id="medication"
              label="Medication"
              disabled
              {...register("medication")}
              error={errors.medication?.message}
            />
            <Select
              options={generatePharmaciesOptions(pharmaciesList)}
              id="pharmacy"
              containerClassName="gap-2"
              control={control}
              label="Pharmacy"
              placeholder="Select pharmacy"
              error={errors?.pharmacy?.message}
              onChange={(e) => {
                setValue("isNewPharmacy", e === OTHER_VALUE);
              }}
            />
            {isNewPharmacyForm(createPharmacy, errors) && (
              <NewPharmacyFormInputs
                pharmacyStateField="newPharmacy.address.state"
                register={register}
                errors={errors.newPharmacy}
                control={control}
              />
            )}
            <TextArea
              label="Comment to provider (Optional)"
              rows={2}
              {...register("comment")}
              id="comment"
            />
          </div>
          <CancelSubmitButtons
            submitButtonLabel="Request"
            isSubmitting={isPendingCreateScript}
            handleCancel={handleClose}
          />
        </form>
      )}
    </Modal>
  );
};

import { create } from "zustand";
import { persist } from "zustand/middleware";

import { RawResponses } from "~/providers/utils/types/rawResponsesTypes";

export interface RawResponsesStore {
  expandedSections: Record<string, boolean>;
  toggleExpandAll(sections: RawResponses, expand: boolean): void;
  toggleSection(code: string): void;
  setPatientId(patientId: string): void;
  setScrollPosition: (position: number) => void;
  scrollPosition: number;
  patientId?: string;
}

export const useRawResponsesStore = create<RawResponsesStore>()(
  persist(
    (set) => ({
      patientId: undefined,
      expandedSections: {},
      scrollPosition: 0,
      setScrollPosition: (position) => set({ scrollPosition: position }),

      setPatientId: (patientId: string) => {
        set(() => ({ patientId }));
      },
      toggleExpandAll: (sections, expand) => {
        const newExpandedState = sections.reduce(
          (acc, { code }) => ({ ...acc, [code]: expand }),
          {},
        );
        set((state) => ({
          expandedSections: {
            ...state.expandedSections,
            ...newExpandedState,
          },
        }));
      },

      toggleSection: (code) => {
        set((state) => ({
          expandedSections: {
            ...state.expandedSections,
            [code]: !state.expandedSections[code],
          },
        }));
      },
    }),
    {
      name: "useRawResponsesStore",
    },
  ),
);

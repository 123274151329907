import { twMerge } from "tailwind-merge";

import { getAnswerProps } from "~/common/utils/checkIn/getAnswerProps";
import { getFormattedAnswers } from "~/common/utils/checkIn/getFormattedAnswers";
import {
  CheckInCodes,
  CompletedAnswerData,
  INPUT_TYPES,
  PatientCheckInQuestion,
} from "~/common/utils/types/checkInTypes";
import { RenderAnswer } from "./RenderAnswer";

interface AnswerRendererProps {
  answers?: CompletedAnswerData[];
  questions?: PatientCheckInQuestion[];
  checkinCode?: CheckInCodes;
  className?: string;
  isPreview?: boolean;
  answerClassName?: string;
  containerClassName?: string;
}

export const AnswersRenderer = ({
  answers,
  questions,
  checkinCode,
  className,
  isPreview = false,
  answerClassName,
  containerClassName,
}: AnswerRendererProps) => {
  const formattedAnswers = getFormattedAnswers(checkinCode, answers);

  return (
    <div className={twMerge("flex flex-col gap-2", className)}>
      {!!formattedAnswers?.length &&
        formattedAnswers?.map(
          (answer) =>
            answer.value.answer_type !== INPUT_TYPES.free_text && (
              <RenderAnswer
                answerClassName={answerClassName}
                containerClassName={containerClassName}
                isPreview={isPreview}
                key={answer.id}
                {...getAnswerProps({
                  answerData: answer,
                  answers,
                  questions,
                  isPreview,
                })}
              />
            ),
        )}
    </div>
  );
};

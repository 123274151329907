import tailwindcss from "@headlessui/tailwindcss";
import forms from "@tailwindcss/forms";
import typography from "@tailwindcss/typography";
import type { Config } from "tailwindcss";
import animate from "tailwindcss-animate";

export default {
  // Change this with the extensions you are going to use.
  content: ["./src/**/*.{html,js,jsx,md,mdx,svelte,ts,tsx,vue,svg}"],
  theme: {
    screens: {
      // These are the default media queries.
      // We're declaring them to make it easier to import and use in react for js checks
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
      "2xl": "1536px",
    },
    extend: {
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
        "fade-in": {
          "0%": { opacity: "0", transform: "translateY(-10px)" },
          "100%": { opacity: "1", transform: "translateY(0)" },
        },
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
        "fade-in": "fade-in 0.5s ease-out",
      },
      boxShadow: {
        soft: "0px 0px 7px -5px rgba(0,0,0,0.75)",
        strong: "0px 0px 8px -5px rgba(0,0,0,1)",
      },
      colors: {
        primary: {
          10: "#97B8F766",
          20: "#F7F9FE",
          30: "#DAE5FD",
          50: "#F0F4FE",
          100: "#DDE7FD",
          200: "#C1D4FB",
          300: "#97B8F7",
          500: "#4873EB",
          600: "#3154E4",
          800: "#2433A8",
          900: "#233285",
          DEFAULT: "#4873EB",
        },
        gray: {
          5: "#3F454D",
          10: "#596574",
          20: "#979AA0",
          30: "#4C5560",
          35: "#525D6A",
          40: "#454D56",
          60: "#858C95",
          70: "#323539",
          80: "#2B2D2F",
          90: "#A1A4AC",
        },
        neutral: {
          5: "#EAEBF0",
          10: "#F1F3F4",
          20: "#F7F7F8",
          25: "#FAFBFC",
          60: "#E5E5E7",
          80: "#C1C3C7",
          100: "#F9F9F9",
          300: "#F5F5F5",
          700: "#D7D7D7",
        },
        orange: {
          10: "#FFE4C0",
          20: "#FFDDA1",
          25: "#FFFAF2",
          70: "#D78100",
          80: "#A15C00",
          100: "#E36732",
          900: "#6B3D00",
        },
        interaction: {
          "side-effects": "#FFF8F2",
          status: "#F8F3FF",
          "health-factors": "#FEF5F9",
        },
        success: {
          50: "#E2FBE8",
          200: "#B5F3C3",
          950: "#0D3510",
        },
        complementary: {
          success: { 950: "#0D3510" },
        },
        warning: {
          complementary: "#FFD08A",
          DEFAULT: "#D78100",
        },
        error: "#E33B32",
      },
      fontFamily: {
        sans: ["Inter", "sans-serif"],
        outfit: ["Outfit", "sans-serif"],
      },
      width: {
        drawer: "34.7rem",
        "drawer-prescriptions": "68rem",
      },
      height: {
        "screen-calculated": "100dvh",
        table: "35.625rem",
      },
      maxHeight: {
        "custom-500": "31.25rem",
        "custom-600": "37.5rem",
        "screen-70": "70vh",
        "order-modals": "32rem",
      },
      minHeight: {
        "screen-calculated": "100dvh",
      },
      borderWidth: {
        "1.5": "0.094rem",
      },
    },
  },
  plugins: [typography, forms, tailwindcss, animate],
} satisfies Config;

import { Outlet } from "react-router-dom";

import NavMenu from "~/common/components/ui/NavMenu";
import { HeaderInfo } from "~/patients/components/home";
import { useGetCurrentPatient } from "~/patients/hooks/queries/useGetCurrentPatient";
import { useUiStore } from "~/patients/stores";

export const ConsultationHomeLayout = () => {
  const { data: user } = useGetCurrentPatient();
  const { setSideBarOpen } = useUiStore();
  const isInactivePatient = !!user?.archived_at;

  return (
    <div className="relative flex h-screen-calculated w-full flex-col text-sm">
      <NavMenu
        headerInfo={
          <HeaderInfo
            subtitle={`Welcome back, ${user?.name}`}
            title={
              isInactivePatient
                ? "Your consultation has ended"
                : "Let’s get to know you"
            }
          />
        }
        handleOpenSideBar={() => setSideBarOpen(true)}
      />
      <Outlet />
    </div>
  );
};

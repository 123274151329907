import { z } from "zod";

import { TEXT_MAX_LENGTH } from "~/common/constants";
import { BUNDLES_CODES_VALUES } from "~/common/constants/bundles";
import {
  DURATION_INTERVALS_VALUES,
  FREQUENCIES_INTERVALS_VALUES,
  REPEAT_UNTIL_VALUES,
  repeatUntilOptions,
} from "../constants/ordersFrequencies";
import {
  orderTypeValues,
  scheduleAppointmentTypeValues,
} from "./types/orderTypeValues";

const notesSchema = z.object({
  patientNote: z
    .string()
    .max(
      TEXT_MAX_LENGTH,
      `Please ensure the note does not exceed ${TEXT_MAX_LENGTH} characters.`,
    )
    .optional(),
  internalNote: z
    .string()
    .max(
      TEXT_MAX_LENGTH,
      `Please ensure the note does not exceed ${TEXT_MAX_LENGTH} characters.`,
    )
    .optional(),
});

export const bundleFormSchema = notesSchema.extend({
  orderType: z.enum(BUNDLES_CODES_VALUES),
  startDate: z
    .string()
    .min(1, "Please select a start date for the medication change."),
  patientMedicationId: z.string({
    required_error: "Please select a medication.",
  }),
});

export const checkInFormSchema = notesSchema.extend({
  orderType: z.enum(orderTypeValues),
  recurringOrder: z.literal(false),
  dueDate: z.string().min(1, "Please select a due date for the check-in."),
});

export const scheduleFormSchema = notesSchema
  .extend({
    orderType: z.enum(orderTypeValues),
    recurringOrder: z.literal(true),
    dueDate: z.string().min(1, "Please select a due date for the check-in."),
    frequency: z.enum(FREQUENCIES_INTERVALS_VALUES, {
      errorMap: () => ({
        message: "Please select a frequency for the check-in.",
      }),
    }),
    repeatUntil: z.enum(REPEAT_UNTIL_VALUES, {
      errorMap: () => ({
        message: "Please specify until when this check-in should be repeated.",
      }),
    }),
    endDate: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.repeatUntil === repeatUntilOptions.ends) {
        return !!data.endDate;
      }
      return true;
    },
    {
      path: ["endDate"],
      message: "Please select the end date for the check-ins.",
    },
  );

export const scheduleAppointmentFormSchema = notesSchema.extend({
  orderType: z.literal(scheduleAppointmentTypeValues),
  duration: z.enum(DURATION_INTERVALS_VALUES, {
    message: "Please provide an appointment duration",
  }),
  dueDate: z.string().min(1, "Please select due date for the appointment."),
});

export const addOrderSchema = z.union([
  checkInFormSchema,
  scheduleAppointmentFormSchema,
  scheduleFormSchema,
  bundleFormSchema,
]);

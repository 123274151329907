import { PropsWithChildren } from "react";
import { tv } from "tailwind-variants";

import { SectionHeader, Typography } from "~/common/components";
import { TYPOGRAPHY_VARIANTS } from "~/common/utils";

export const CONSULTATION_FIELD_LAYOUT_VARIANTS = {
  primary: "primary",
  secondary: "secondary",
} as const;

type ConsultationFieldLayoutVariantType =
  (typeof CONSULTATION_FIELD_LAYOUT_VARIANTS)[keyof typeof CONSULTATION_FIELD_LAYOUT_VARIANTS];
interface CompletedConsultationFieldLayoutProps extends PropsWithChildren {
  title: string;
  description?: string;
  extraData?: string;
  variant?: ConsultationFieldLayoutVariantType;
}

const layoutClasses = tv({
  base: "flex w-full flex-col rounded-md",
  variants: {
    variant: {
      primary: "border border-neutral-60 p-5 gap-3 section",
      secondary: "gap-1.5 secondary-section",
    },
  },
});

const headerClasses = tv({
  base: "font-sans first-letter:uppercase",
  variants: {
    variant: {
      primary: "text-xl font-medium section-header",
      secondary: "text-lg font-normal secondary-header",
    },
  },
});

export const CompletedConsultationFieldLayout = ({
  title,
  description,
  extraData,
  children,
  variant = CONSULTATION_FIELD_LAYOUT_VARIANTS.primary,
}: CompletedConsultationFieldLayoutProps) => {
  return (
    <div className={layoutClasses({ variant })}>
      <SectionHeader className={headerClasses({ variant })}>
        {title}
      </SectionHeader>

      {description && (
        <Typography
          variant={TYPOGRAPHY_VARIANTS.light}
          className="section-content text-base text-gray-10 first-letter:uppercase"
        >
          {description}
        </Typography>
      )}
      {extraData && (
        <Typography
          variant={TYPOGRAPHY_VARIANTS.secondary}
          className="text-base italic"
        >
          {extraData}
        </Typography>
      )}
      {children}
    </div>
  );
};

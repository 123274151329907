import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import { getCharactersCount } from "~/common/utils";
import { ErrorText } from "./typography";

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  containerClassName?: string;
  label?: string;
  rows?: number;
  maxCharacters?: number;
  charactersCount?: number;
  error?: string;
  children?: ReactNode;
}

const TextArea = React.forwardRef(
  (
    {
      containerClassName,
      label,
      charactersCount = 0,
      maxCharacters,
      className,
      id,
      rows,
      placeholder,
      error,
      children,
      ...props
    }: TextAreaProps,
    ref: React.Ref<HTMLTextAreaElement>,
  ) => {
    return (
      <div
        className={twMerge(
          "flex w-full flex-col gap-2 font-light text-gray-70",
          containerClassName,
        )}
      >
        {(label ?? children) && (
          <div className="flex justify-between">
            <div className="flex w-full flex-col">
              <label className="text-sm font-medium" htmlFor={id}>
                {label}
              </label>
              {children && (
                <p className="text-sm font-normal leading-5 text-gray-35">
                  {children}
                </p>
              )}
            </div>
          </div>
        )}
        <div className="relative">
          <textarea
            ref={ref}
            id={id}
            placeholder={placeholder}
            rows={rows}
            className={twMerge(
              "block w-full resize-none rounded-md border border-gray-300 bg-neutral-25 p-2.5 text-sm",
              "focus:border-gray-20 focus:outline-none focus:ring-0",
              "placeholder:text-sm placeholder:italic",
              "disabled:bg-gray-200 disabled:text-gray-700",
              className,
              error && "border-error bg-red-50 focus:border-error",
            )}
            aria-errormessage={`${id}_error`}
            {...props}
          />
        </div>
        {maxCharacters && (
          <span className="mr-1 self-end text-xs">
            {getCharactersCount(charactersCount, maxCharacters)}
          </span>
        )}
        {error && <ErrorText>{error}</ErrorText>}
      </div>
    );
  },
);

TextArea.displayName = "TextArea";

export default TextArea;

import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";

import TextArea from "~/common/components/ui/Textarea";
import { replaceUnderscores } from "~/common/utils";
import { INTAKE_QUESTION_TYPES } from "~/common/utils/consultations/intake";
import {
  CompletedRadioWithTextAnswer,
  RadioQuestionWithTextData,
  RadioWithTextAnswer,
} from "~/common/utils/consultations/intake/interfaces";
import { ConsultationQuestionsLayout } from "~/patients/components";
import RadioButtonOption from "~/patients/components/ui/RadioButtonOption";
import {
  radioWithOptionalTextAnswerSchema,
  radioWithTextAnswerSchema,
  RadioWithTextAnswerType,
} from "~/patients/utils/consultations/intakes";

export interface RadioWithTextFormProps {
  question: RadioQuestionWithTextData;
  handleGoBack: () => void;
  onSubmit: (answer: RadioWithTextAnswer) => void;
  isSubmitting: boolean;
  disableBack: boolean;
  completedAnswer: CompletedRadioWithTextAnswer;
}

export const RadioWithTextForm = ({
  question,
  handleGoBack,
  onSubmit,
  isSubmitting,
  disableBack,
  completedAnswer,
}: RadioWithTextFormProps) => {
  const { options, title, type } = question;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm<RadioWithTextAnswerType>({
    defaultValues: {
      answer: {
        ...completedAnswer,
      },
    },
    resolver: zodResolver(
      type === INTAKE_QUESTION_TYPES.radio_with_text
        ? radioWithTextAnswerSchema
        : radioWithOptionalTextAnswerSchema,
    ),
  });

  const saveAnswers: SubmitHandler<RadioWithTextAnswerType> = (data) => {
    const input: RadioWithTextAnswer = {
      answer: { ...data.answer },
    };
    onSubmit(input);
  };

  const valueSelected = watch("answer.value");
  const optionSelected = options.find(({ value }) => value === valueSelected);

  useEffect(() => {
    if (!errors.answer?.extra_data) return;
    clearErrors("answer.extra_data");
  }, [optionSelected]);

  return (
    <ConsultationQuestionsLayout
      handleNext={handleSubmit(saveAnswers)}
      handleBack={handleGoBack}
      disableNext={!valueSelected}
      errors={errors.answer?.message}
      isSubmitting={isSubmitting}
      title={title}
      disableBack={disableBack}
    >
      {options.map(({ value }) => (
        <RadioButtonOption
          key={value}
          label={replaceUnderscores(value)}
          value={value}
          isSelected={valueSelected === value}
          {...register("answer.value")}
        />
      ))}
      {optionSelected?.asksExtraInfo && (
        <TextArea
          key={optionSelected.value}
          label={optionSelected.labelAsksExtraInfo}
          rows={5}
          placeholder={optionSelected.placeholderAsksExtraInfo}
          id="answer.extra_data"
          {...register("answer.extra_data")}
          error={errors.answer?.extra_data?.message}
        />
      )}
    </ConsultationQuestionsLayout>
  );
};

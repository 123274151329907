import { Fragment, PropsWithChildren, ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

import { CloseButton } from "./CloseButton";

interface DrawerProps extends PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
  header?: ReactNode;
  containerClassName?: string;
  childrenContainerClassName?: string;
  showCloseButton?: boolean;
}

export const Drawer = ({
  isOpen,
  onClose,
  children,
  header,
  childrenContainerClassName,
  containerClassName,
  showCloseButton = true,
}: DrawerProps) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80" />
        </Transition.Child>

        <div
          className={twMerge(
            "fixed inset-y-0 right-0 flex",
            containerClassName,
          )}
        >
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <Dialog.Panel className="relative flex transform bg-white">
              <div
                className={twMerge(
                  "flex w-screen flex-col gap-6 p-6 md:w-drawer",
                  childrenContainerClassName,
                )}
              >
                <div className="flex w-full flex-row gap-3">
                  {header}
                  {showCloseButton && (
                    <CloseButton
                      className={twMerge(
                        "text-gray-70",
                        header && "mt-1 w-fit items-start",
                      )}
                      onClose={onClose}
                    />
                  )}
                </div>
                {children}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

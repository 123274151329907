import {
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  format,
  parseISO,
} from "date-fns";

import { DateObject } from "./types";

export const formatDateDayMonth = (dateString: string): DateObject => {
  const date = new Date(dateString);
  return {
    day: format(date, "dd"),
    month: format(date, "MMM"),
  };
};

export const weeksSince = (dateString?: string) => {
  if (!dateString) return;
  const givenDate = parseISO(dateString);
  const today = new Date();
  const weeksDifference = Math.max(differenceInWeeks(today, givenDate), 1);

  return `${weeksDifference} ${weeksDifference > 1 ? "weeks" : "week"}`;
};

export const monthsSince = (dateString?: string) => {
  if (!dateString) return;
  const givenDate = parseISO(dateString);
  const today = new Date();
  const monthDifference = Math.max(differenceInMonths(today, givenDate), 1);

  return `${monthDifference} ${monthDifference > 1 ? "months" : "month"}`;
};

export const differenceInDaysFromToday = (dateString: string) => {
  const givenDate = parseISO(dateString);
  const today = new Date();
  return differenceInDays(today, givenDate);
};

export const daysSince = (dateString?: string) => {
  if (!dateString) return;

  const daysDifference = differenceInDaysFromToday(dateString);

  if (daysDifference > 30) return monthsSince(dateString);
  if (daysDifference >= 7) return weeksSince(dateString);
  if (daysDifference === 0) return "today";
  if (daysDifference === 1) return "yesterday";

  return `${daysDifference} days`;
};

export const formatDateWithHour = (dateString: string) => {
  const date = new Date(dateString);

  return format(date, "eee, dd MMM 'at' hh:mm a");
};

import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, SubmitHandler, useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { CancelSubmitButtons, WarningMessage } from "~/common/components";
import Modal from "~/common/components/Modal";
import Input from "~/common/components/ui/Input";
import Select from "~/common/components/ui/select/Select";
import Spinner from "~/common/components/ui/Spinner";
import TextArea from "~/common/components/ui/Textarea";
import { OTHER_VALUE } from "~/common/constants";
import { NewPharmacyFormInputs } from "~/patients/components";
import CheckBox from "~/patients/components/ui/CheckBox";
import { useGetPatientPharmacies } from "~/patients/hooks/queries/pharmacy";
import { useOnSubmitIssue } from "~/patients/hooks/queries/scripts/useOnSubmitIssue";
import {
  generatePharmaciesOptions,
  NewPharmacyResolveIssueFormValuesType,
  ResolveIssueFormValuesType,
  resolveIssueSchema,
  TEXT_MEDIUM_LENGTH,
} from "~/patients/utils";

interface ResolveIssueModalProps {
  show: boolean;
  onClose: () => void;
  medication?: string;
  scriptToResolveIssueId?: string;
}

export const ResolveIssueModal = ({
  onClose,
  show,
  medication,
  scriptToResolveIssueId,
}: ResolveIssueModalProps) => {
  const formDescription =
    "If there was issue filling your prescription, please update your request and resubmit.";

  const { data: pharmaciesList, isLoading } = useGetPatientPharmacies({
    enabled: !!scriptToResolveIssueId,
  });

  const {
    register,
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ResolveIssueFormValuesType>({
    resolver: zodResolver(resolveIssueSchema),
    defaultValues: {
      medication,
      isNewPharmacy: false,
    },
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const { requestResolveIssue, isPending } = useOnSubmitIssue(handleClose);

  const onSubmit: SubmitHandler<ResolveIssueFormValuesType> = (formData) => {
    requestResolveIssue(formData, scriptToResolveIssueId);
  };

  const createPharmacy = watch("isNewPharmacy");

  const isNewPharmacyForm = (
    data: boolean,
    errors: FieldErrors<ResolveIssueFormValuesType>,
  ): errors is FieldErrors<NewPharmacyResolveIssueFormValuesType> => {
    return data;
  };

  return (
    <Modal
      show={show}
      onClose={handleClose}
      title="Resolve an issue"
      description={formDescription}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <form
          className="flex h-full flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div
            className={twMerge(
              "flex h-full w-full flex-col gap-4",
              createPharmacy && "max-h-custom-600 overflow-auto",
            )}
          >
            <Input
              id="medication"
              label="Medication"
              disabled
              {...register("medication")}
              error={errors.medication?.message}
            />
            <Select
              options={generatePharmaciesOptions(pharmaciesList)}
              id="pharmacy"
              containerClassName="gap-2"
              control={control}
              label="Pharmacy"
              placeholder="Select pharmacy"
              error={errors?.pharmacy?.message}
              onChange={(e) => {
                setValue("isNewPharmacy", e === OTHER_VALUE);
              }}
            />
            {isNewPharmacyForm(createPharmacy, errors) && (
              <NewPharmacyFormInputs
                pharmacyStateField="newPharmacy.address.state"
                register={register}
                errors={errors.newPharmacy}
                control={control}
              />
            )}
            <TextArea
              label="Reason"
              rows={2}
              error={errors.reason?.message}
              {...register("reason")}
              id="reason"
              charactersCount={watch("reason")?.length}
              maxCharacters={TEXT_MEDIUM_LENGTH}
            />
            <div className="flex w-full flex-col gap-2">
              <CheckBox
                id="pre_authorization_request"
                {...register("pre_authorization_request")}
              >
                Ask for a Pre-authorization
              </CheckBox>
              {watch("pre_authorization_request") && (
                <WarningMessage>
                  Please check the Pre-authorization option only if your{" "}
                  <span className="font-medium">
                    insurance company declined the claim.
                  </span>
                </WarningMessage>
              )}
            </div>
          </div>
          <CancelSubmitButtons
            submitButtonLabel="Request"
            isSubmitting={isPending}
            handleCancel={handleClose}
          />
        </form>
      )}
    </Modal>
  );
};

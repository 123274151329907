import React, { PropsWithChildren } from "react";

import { Button } from "../ui/button";

interface FormLayoutProps extends PropsWithChildren {
  description?: string;
  handleGoBack?: () => void;
  handleSubmit: () => void;
  submitLabel: string;
  title: string;
  disableContinue?: boolean;
  pendingRequest?: boolean;
}

const FormLayout = ({
  children,
  description,
  handleGoBack,
  handleSubmit,
  submitLabel,
  title,
  disableContinue = false,
  pendingRequest = false,
}: FormLayoutProps) => {
  return (
    <div className="flex w-full flex-col justify-center gap-6 overflow-y-auto bg-transparent text-gray-700 md:w-1/2">
      <h2 className="text-left font-outfit text-3xl font-semibold tracking-wide lg:text-4xl">
        {title}
      </h2>
      {description && <p>{description}</p>}
      <form onSubmit={handleSubmit} className="flex flex-col gap-6">
        <div className="flex flex-col gap-4">{children}</div>
        <div className="flex w-full flex-col justify-between gap-2 md:flex-row-reverse">
          <Button
            type="submit"
            disabled={disableContinue}
            isLoading={pendingRequest}
          >
            {submitLabel}
          </Button>
          {handleGoBack && (
            <Button
              variant="outline"
              className="lg:w-1/2"
              onClick={handleGoBack}
            >
              Go Back
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default FormLayout;

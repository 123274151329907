import { matchPath, useNavigate } from "react-router-dom";

import { PillsIcon } from "~/common/components";
import HomeIcon from "~/common/components/icons/HomeIcon";
import LogOutIcon from "~/common/components/icons/LogOutIcon";
import SettingsIcon from "~/common/components/icons/SettingsIcon";
import { PATIENT_STATUS } from "~/common/constants";
import { BASE_MEDICATION_PATH, ROUTES } from "../router";
import { useUiStore } from "../stores";
import { getHomePath } from "../utils";
import { useGetCurrentPatient } from "./queries/useGetCurrentPatient";

export const useSideBarItems = (
  actualPath: string,
  handleSignOut: () => void,
) => {
  const { data: user } = useGetCurrentPatient();

  const navigate = useNavigate();
  const { setSideBarOpen } = useUiStore();
  const isActivePatient = user?.status === PATIENT_STATUS.ongoing_care;

  const homePath = getHomePath(user?.status);
  const isHomeTabCurrentItem = isActivePatient
    ? !!matchPath(homePath, actualPath) ||
      !!matchPath(ROUTES.completedCheckins, actualPath)
    : !!matchPath(homePath, actualPath);
  const isInactivePatient = !!user?.archived_at;

  const menuItems = [
    {
      current: isHomeTabCurrentItem,
      Icon: HomeIcon,
      name: "Home",
      onClick: () => {
        setSideBarOpen(false);
        navigate(homePath);
      },
    },
    {
      current: !!matchPath(`${BASE_MEDICATION_PATH}/*`, actualPath),
      Icon: PillsIcon,
      name: "My medications",
      onClick: () => {
        setSideBarOpen(false);
        navigate(ROUTES.medications);
      },
      disabled: !isActivePatient || isInactivePatient,
    },
  ];

  const bottomMenuItems = [
    {
      current: !!matchPath(ROUTES.settings, actualPath),
      Icon: SettingsIcon,
      name: "Settings",
      onClick: () => {
        setSideBarOpen(false);
        navigate(ROUTES.settings);
      },
      disabled: !isActivePatient,
    },
    {
      Icon: LogOutIcon,
      name: "Sign Out",
      onClick: handleSignOut,
    },
  ];

  return { menuItems, bottomMenuItems };
};

import React, { ComponentPropsWithoutRef, ReactNode, useState } from "react";
import { twMerge } from "tailwind-merge";

import ClosedEyeIcon from "../icons/ClosedEyeIcon";
import OpenedEyeIcon from "../icons/OpenedEyeIcon";
import { ErrorText } from "./typography";

interface InputProps extends ComponentPropsWithoutRef<"input"> {
  label?: string;
  placeholder?: string;
  error?: string;
  className?: string;
  children?: ReactNode;
  defaultValue?: string;
  isError?: boolean;
  inputClassName?: string;
  description?: string;
  containerClassName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      label,
      error,
      type = "text",
      placeholder,
      className,
      defaultValue,
      children,
      isError = false,
      inputClassName,
      description,
      containerClassName,
      ...props
    }: InputProps,
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const passwordVisible = showPassword ? "text" : "password";
    const isCurrency = type === "currency";

    return (
      <div
        className={twMerge(
          "flex w-full flex-col gap-2 font-light text-gray-700",
          containerClassName,
        )}
      >
        {(label ?? description) && (
          <div className="flex w-full flex-col">
            {label && (
              <label
                className="flex w-full justify-between text-sm font-medium"
                htmlFor={id}
              >
                <span className="first-letter:capitalize">{label}</span>
              </label>
            )}
            {description && <p className="text-sm">{description}</p>}
          </div>
        )}
        <div
          className={twMerge(
            "flex w-full items-center justify-between rounded-md border border-gray-300 bg-neutral-25 text-sm",
            className,
            (isError || error) && "border-error bg-red-50",
          )}
        >
          {isCurrency && <span className="px-3 text-gray-500">$</span>}
          <input
            className={twMerge(
              "h-11 w-full rounded-md border-0 bg-transparent text-sm placeholder:text-left focus:outline-none focus:ring-0 disabled:bg-gray-200 disabled:text-gray-700",
              (isError || error) && "text-error",
              inputClassName,
              isCurrency && "pl-0",
            )}
            id={id}
            placeholder={placeholder ?? `Enter ${label?.toLocaleLowerCase()}`}
            {...props}
            ref={ref}
            type={type === "password" ? passwordVisible : type}
            defaultValue={defaultValue}
          />
          {children}
          {type === "password" && (
            <button
              className="px-4 focus:outline-none focus:ring-transparent"
              type="button"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <OpenedEyeIcon className={error && "text-error"} />
              ) : (
                <ClosedEyeIcon className={error && "text-error"} />
              )}
            </button>
          )}
        </div>
        {error?.trim() && <ErrorText>{error}</ErrorText>}
      </div>
    );
  },
);

Input.displayName = "Input";
export default Input;

import { z } from "zod";

import {
  PATIENT_SCRIPT_STATUS,
  PATIENT_SCRIPT_STATUS_FINAL_STATES_VALUES,
  TEXT_MAX_LENGTH,
} from "~/common/constants";
import { TEXT_MEDIUM_LENGTH } from "~/providers/constants";

const medicationSchema = z.object({
  id: z.string(),
  paused: z.boolean(),
});

export const pauseScriptSchema = z.object({
  patient_medications: z.array(medicationSchema),
  internal_note: z
    .string()
    .min(1, "Please add an internal note to justify your changes")
    .max(
      TEXT_MEDIUM_LENGTH,
      `Please ensure the internal note does not exceed ${TEXT_MEDIUM_LENGTH} characters.`,
    ),
});

const finalStatusesScriptSchema = z.object({
  owner_id: z.string().nullable(),
  status: z.enum(PATIENT_SCRIPT_STATUS_FINAL_STATES_VALUES, {
    required_error: "Please select script's status.",
  }),
  resolution: z
    .string()
    .min(1, {
      message: "Please provide a resolution for this status change",
    })
    .max(
      TEXT_MAX_LENGTH,
      `Please ensure the resolution does not exceed ${TEXT_MAX_LENGTH} characters.`,
    )
    .default(""),
});

const pendingStatusesScriptSchema = z.object({
  owner_id: z.string().nullable(),
  status: z.literal(PATIENT_SCRIPT_STATUS.pending, {
    required_error: "Please select script's status.",
  }),
  resolution: z
    .string()
    .max(
      TEXT_MAX_LENGTH,
      `Please ensure the resolution does not exceed ${TEXT_MAX_LENGTH} characters.`,
    )
    .optional(),
});

export const editScriptSchema = z.discriminatedUnion("status", [
  finalStatusesScriptSchema,
  pendingStatusesScriptSchema,
]);

export const submitScriptCommentSchema = z.object({
  text: z
    .string()
    .max(
      TEXT_MAX_LENGTH,
      `Please ensure the comment does not exceed ${TEXT_MAX_LENGTH} characters.`,
    ),
});

export type PauseScriptFormValuesType = z.infer<typeof pauseScriptSchema>;
export type PauseMedicationFormValuesType = z.infer<typeof medicationSchema>;
export type EditScriptSchemaFormValuesType = z.infer<typeof editScriptSchema>;
export type SubmitCommentFormValuesType = z.infer<
  typeof submitScriptCommentSchema
>;
